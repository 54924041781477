import React from 'react'
import PropTypes from 'prop-types'

// custom components
import CustomToolTip from '../CustomToolTip'
import CopyToClipboard from '../../../utils/vinCopyToClickbord/vinCopyToClickbord'

import { Icons } from '../../Icons'

const {
  BiSolidColorFill,
  GiCarDoor,
  GiGearStickPattern,
  GrDocumentText,
  IoCarSportOutline,
  IoCheckmarkOutline,
  IoSpeedometerOutline,
  LuCalendarDays,
  LuFuel,
  MdAirlineSeatReclineExtra,
  MdCarRepair,
  MdOutlineSimCard,
  PiCylinderThin,
  PiSteeringWheel,
  TbEngine,
  VscTypeHierarchy,
} = Icons

const CustomCarDetails = ({ carDetails }) => {
  const detaisconvert = (fieldData) => {
    const data = fieldData && fieldData.trim()

    let value = data

    if (data !== 'Standard' && data !== 'Optional') {
      value = data
    }
    if (data === 'Standard') {
      value = 'Yes'
    }
    if (data === 'Optional') {
      value = 'No'
    }
    if (data === '' || !data) {
      value = ''
    }

    return value
  }

  return (
    <div className='flex flex-wrap items-start order-3 large:items-stretch justify-start m-0 p-0 w-full pb-10 last:pb-0 mb-[40px] last:mb-0'>
      <div className='w-full'>
        <div className='pb-10 mb-10 border-0 border-b details_overview last:pb-0 last:mb-0 last:border-b-0 border-gray border-opacity-10'>
          <div className='relative flex flex-wrap items-start justify-start w-full gap-5 p-0 pb-8 m-0 mb-8 border-b last:pb-0 last:mb-0 border-gray/10 last:border-none'>
            <div className='w-full'>
              <div className='flex flex-row items-center gap-2 mb-5 title title-gray'>
                <span className='w-5 h-[1px] bg-primary block'></span>
                <h3 className='subHeading-3'>
                  Get to know this&nbsp;
                  <b>
                    {detaisconvert(carDetails?.make)}&nbsp;
                    {detaisconvert(carDetails?.model)}
                  </b>
                </h3>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {detaisconvert(carDetails?.vin_number) && (
                    <li>
                      <div className='text'>
                        <GrDocumentText className='text-primary' />
                        VIN
                      </div>
                      <div className='value'>
                        <CopyToClipboard>
                          {detaisconvert(carDetails?.vin_number)}
                        </CopyToClipboard>
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.make) && (
                    <li>
                      <div className='text'>
                        <MdCarRepair className='text-primary' />
                        Make
                      </div>
                      <div className='value'>
                        <span className='pe-1'>
                          {detaisconvert(carDetails?.make)}
                        </span>
                      </div>
                    </li>
                  )}

                  {detaisconvert(carDetails?.make) &&
                    detaisconvert(carDetails?.model) && (
                      <li>
                        <div className='text'>
                          <IoCarSportOutline className='text-primary' />
                          Model
                        </div>
                        <div className='value'>
                          <span className='pe-1'>
                            {detaisconvert(carDetails?.make)}
                            &nbsp;
                            {detaisconvert(carDetails?.model)}
                          </span>
                        </div>
                      </li>
                    )}

                  {detaisconvert(carDetails?.trim) && (
                    <li>
                      <div className='text'>
                        <VscTypeHierarchy className='text-primary' />
                        Trim
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.trim)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.year) && (
                    <li>
                      <div className='text'>
                        <LuCalendarDays className='text-primary' />
                        Year
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.year)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.stock_number) && (
                    <li>
                      <div className='text'>
                        <MdOutlineSimCard className='text-primary' />
                        Stock
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.stock_number)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.engine) && (
                    <li>
                      <div className='text'>
                        <TbEngine className='text-primary' />
                        Engine
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.engine)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.fuel_type) && (
                    <li>
                      <div className='text'>
                        <LuFuel className='text-primary' />
                        Fuel Type
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.fuel_type)}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {(detaisconvert(carDetails?.odometer) ||
                    detaisconvert(carDetails?.mileage_unit)) && (
                    <li>
                      <div className='text'>
                        <IoSpeedometerOutline className='text-primary' />
                        Odometer
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.odometer)}
                        &nbsp;
                        {detaisconvert(carDetails?.mileage_unit)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.transmission_type) && (
                    <li>
                      <div className='text'>
                        <GiGearStickPattern className='text-primary' />
                        Transmission
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.transmission_type)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.drivetrain) && (
                    <li>
                      <div className='text'>
                        <PiSteeringWheel className='text-primary' />
                        Drive Type
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.drivetrain)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.engine_cylinders) && (
                    <li>
                      <div className='text'>
                        <PiCylinderThin className='text-primary' />
                        Cylinder
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.engine_cylinders)}
                      </div>
                    </li>
                  )}

                  {detaisconvert(carDetails?.doors) && (
                    <li>
                      <div className='text'>
                        <GiCarDoor className='text-primary' />
                        Doors
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.doors)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.standard_seating) && (
                    <li>
                      <div className='text'>
                        <MdAirlineSeatReclineExtra className='text-primary' />
                        Seating
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.standard_seating)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.exterior_color) && (
                    <li>
                      <div className='text'>
                        <BiSolidColorFill className='text-primary' />
                        Exterior Color
                      </div>
                      <div className='value'>
                        <span aria-label={carDetails?.exterior_color}>
                          <CustomToolTip label={carDetails?.exterior_color}>
                            <span></span>
                          </CustomToolTip>
                        </span>
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.interior_color) && (
                    <li>
                      <div className='text'>
                        <BiSolidColorFill className='text-primary' />
                        Interior Color
                      </div>
                      <div className='value'>
                        <span aria-label={carDetails?.interior_color}>
                          <CustomToolTip label={carDetails?.interior_color}>
                            <span></span>
                          </CustomToolTip>
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className='relative flex flex-wrap items-start justify-start w-full gap-5 p-0 pb-8 m-0 mb-8 border-b last:pb-0 last:mb-0 border-gray/10 last:border-none'>
            <div className='w-full'>
              <div className='flex flex-wrap items-center gap-2 mb-5 title title-gray'>
                <span className='w-5 h-[1px] bg-primary block'></span>
                <h3 className='subHeading-3'>Vehicle conditions</h3>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {detaisconvert(carDetails?.ABS_brakes) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        ABS Brakes
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.ABS_brakes)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.front_side_airbag) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Front Side Airbag
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.front_side_airbag)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.side_head_curtain_airbag) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Side Air Bag
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.side_head_curtain_airbag)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.air_conditioning) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Air Conditioning
                      </div>
                      <div className='value'>
                        <span className='pe-1'>
                          {detaisconvert(carDetails?.air_conditioning)}
                        </span>
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.child_safety_door_locks) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        <p className='w-[calc(100%_-_26px)]'>
                          Child Safety Door Locks
                        </p>
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.child_safety_door_locks)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.cruise_control) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Cruise Control
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.cruise_control)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.fog_lights) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Fog Lights
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.fog_lights)}
                      </div>
                    </li>
                  )}

                  {detaisconvert(carDetails?.has_accident) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Has Accident
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.has_accident)}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className='w-full lg:flex-1'>
              <div className='listing-detail-detail'>
                <ul>
                  {detaisconvert(carDetails?.front_brake_type) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Front Brake Type
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.front_brake_type)}
                      </div>
                    </li>
                  )}

                  {detaisconvert(carDetails?.windshield) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Windshield
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.windshield)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.structural_parts) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Structural parts
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.structural_parts)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.suspension) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Suspension
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.suspension)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.tires) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Tires
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.tires)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.manual_sunroof) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Sunroof
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.manual_sunroof)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.navigation_system) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Navigation System
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.navigation_system)}
                      </div>
                    </li>
                  )}
                  {detaisconvert(carDetails?.parking_sensors) && (
                    <li>
                      <div className='text'>
                        <IoCheckmarkOutline className='text-primary' />
                        Parking Sensors
                      </div>
                      <div className='value'>
                        {detaisconvert(carDetails?.parking_sensors)}
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {detaisconvert(carDetails?.description) && (
            <div className='w-full my-6'>
              <div className='flex flex-wrap items-center gap-2 mb-5 title title-gray'>
                <span className='w-5 h-[1px] bg-primary block'></span>
                <h3 className='subHeading-3'>Description</h3>
              </div>
              <div className='content'>
                <p className='!text-[#3b3b3b]'>
                  {detaisconvert(carDetails?.description)}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomCarDetails

CustomCarDetails.propTypes = {
  carDetails: PropTypes.shape({
    vin_number: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    trim: PropTypes.string,
    year: PropTypes.string,
    stock_number: PropTypes.string,
    engine: PropTypes.string,
    fuel_type: PropTypes.string,
    odometer: PropTypes.string,
    mileage_unit: PropTypes.string,
    transmission_type: PropTypes.string,
    drivetrain: PropTypes.string,
    engine_cylinders: PropTypes.string,
    doors: PropTypes.string,
    standard_seating: PropTypes.string,
    exterior_color: PropTypes.string,
    interior_color: PropTypes.string,
    ABS_brakes: PropTypes.string,
    front_side_airbag: PropTypes.string,
    side_head_curtain_airbag: PropTypes.string,
    air_conditioning: PropTypes.string,
    child_safety_door_locks: PropTypes.string,
    cruise_control: PropTypes.string,
    fog_lights: PropTypes.string,
    has_accident: PropTypes.string,
    front_brake_type: PropTypes.string,
    windshield: PropTypes.string,
    structural_parts: PropTypes.string,
    suspension: PropTypes.string,
    tires: PropTypes.string,
    manual_sunroof: PropTypes.string,
    navigation_system: PropTypes.string,
    parking_sensors: PropTypes.string,
    description: PropTypes.string,
  }),
}
