import React, { Fragment, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'

import { Field, FormikProvider, useFormik } from 'formik'
import { useNavigate, useSearchParams } from 'react-router-dom'

// custom components
import CustomInput from '../../../../../custom/CustomInput'
import CustomSelect from '../../../../../custom/CustomSelect'
import CustomToast from '../../../../../components/Custom/CustomToast'
import CustomRadioInput from '../../../../../custom/CustomRadioInput'
import CustomLoadingSection from '../../../../../components/Custom/CustomLoadingSection'

// context
import { useStepContext } from '../../../../../context/useStepContext'

// utils
import axiosInterceptorInstance from '../../../../../utils/axios/axiosInterceptorInstance'
import { handleRequestError } from '../../../../../utils/axios/handleRequestError'

import { Icons } from '../../../../../components/Icons'

const { TiArrowDown, MdArrowRightAlt } = Icons

const fuelTypeList = [
  { value: 'Gasoline', label: 'Gasoline' },
  // { value: 'Petrol', label: 'Petrol' },
  { value: 'Electric', label: 'Electric' },
  { value: 'Diesel', label: 'Diesel' },
]
const transmissionTypeList = [
  { value: 'Automatic', label: 'Automatic' },
  { value: 'Manual', label: 'Manual' },
  { value: 'CVT', label: 'CVT' },
]
const engineCylindersList = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '6', label: '6' },
  { value: '8', label: '8' },
  { value: '10', label: '10' },
  { value: '12', label: '12' },
  { value: '14', label: '14' },
  { value: '16', label: '16' },
]
const drivetrainList = [
  { value: 'Four-Wheel Drive', label: '4WD' },
  { value: 'All-Wheel Drive', label: 'AWD' },
  { value: 'Front-Wheel Drive', label: 'FWD' },
  { value: 'Rear-Wheel Drive', label: 'RWD' },
]
const passengerList = [
  { value: '2', label: '2' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
]
const doorsList = [
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
]

const VinDetails = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const vehicle = searchParams.get('vehicle')
  const [sellersList, setSellersList] = useState([])

  const data = useSelector((store) => store.vehicle)

  useEffect(() => {
    formik.setFieldValue('vin_number', data?.vin)
    formik.setFieldValue('make', data?.attributes?.make)
    formik.setFieldValue('model', data?.attributes?.model)
    formik.setFieldValue('year', data?.attributes?.year)
    formik.setFieldValue('trim', data?.attributes?.trim)
    formik.setFieldValue('fuel_type', data?.attributes?.fuel_type)
    formik.setFieldValue('engine', data?.attributes?.engine)
    formik.setFieldValue('engine_cylinders', data?.attributes?.engine_cylinders)
    formik.setFieldValue(
      'transmission_type',
      data?.attributes?.transmission_type,
    )
    formik.setFieldValue('drivetrain', data?.attributes?.drivetrain)
    formik.setFieldValue('standard_seating', data.attributes?.standard_seating)
    formik.setFieldValue(
      'doors',
      data.attributes?.doors && data.attributes?.doors?.match(/\d/)[0],
    )
  }, [data])

  const fetchSellersData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(`/user/list/sellers`)

      if (response.data.success) {
        const transformedSellers = response.data.userList.map((user) => ({
          value: user?.first_name,
          label: `${user?.first_name} - ${user?.email}`,
          id: user._id,
        }))
        setSellersList(transformedSellers)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const getCarDetails = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInterceptorInstance.get(
        `/vehicle/seller/vehicle-details/details/${vehicle}`,
      )
      if (response.data.success) {
        formik.setValues({
          ...formik.values,
          ...response?.data?.vehicleDetails,
        })
        formik.setFieldValue(
          'username',
          response?.data?.vehicleDetails?.user?.first_name,
        )
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  useEffect(() => {
    fetchSellersData()
    if (vehicle) {
      getCarDetails()
    }
  }, [vehicle])

  const { step, handleStepClick, steps } = useStepContext()

  const goToNextStep = () => {
    if (step < steps.length) {
      handleStepClick(step + 1)
    }
  }

  const [vehicleTrimeList, setVehicleTrimeList] = useState([
    { label: data?.attributes?.trim, value: data?.attributes?.trim },
  ])

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (values, { resetForm }, additionalProps) => {
    const selectedUser = sellersList.find(
      (user) => user.value === values.username,
    )

    try {
      setIsLoading(true)

      const response = await axiosInterceptorInstance.post(
        `/vehicle/seller/vehicle-details/create/${selectedUser.id}`,
        values,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.data.success) {
        if (additionalProps) {
          navigate(`/vehicle/draft`)
          CustomToast({
            message: 'The vehicle has been successfully moved to draft.',
            type: 'success',
          })
          setIsLoading(false)
        } else {
          navigate(
            `/vehicle/add?user=${selectedUser.id}&vehicle=${response.data.vehicleDetails._id}`,
          )
          resetForm()
          setIsLoading(false)
          goToNextStep()
          CustomToast({
            message: response.data.message,
            type: 'success',
          })
        }
      }
    } catch (error) {
      resetForm()
      setIsLoading(false)
      handleRequestError(error)
    }
  }

  const schema = Yup.object().shape({
    trim: Yup.string().when('vehicleTrimeList', {
      is: (vehicleTrimeList) => vehicleTrimeList && vehicleTrimeList.length > 0,
      then: Yup.string().required('Please select trim'),
    }),
    fuel_type: Yup.string().required('Please select fuel type'),
    engine: Yup.string().required('Please select engine'),
    engine_cylinders: Yup.string().required(
      'Please select cylinders information',
    ),
    transmission_type: Yup.string().required('Please select transmission type'),
    standard_seating: Yup.string().required(
      'Please select seating information',
    ),
    drivetrain: Yup.string().required('Please select drivetrain information'),
    username: Yup.string().trim().required('Seller is required.'),
  })

  const formik = useFormik({
    validationSchema: schema,

    onSubmit: (values, formikHelpers, additionalProps) =>
      handleSubmit(values, formikHelpers, additionalProps),
    initialValues: {
      vin_number: '',
      make: '',
      model: '',
      year: '',
      trim: '',
      odometer: '',
      fuel_type: '',
      engine: '',
      engine_cylinders: '',
      transmission_type: '',
      drivetrain: '',
      standard_seating: '',
      doors: '',
      username: '',
    },
  })

  const {
    isSubmitting,
    values: { trim, fuel_type, engine, engine_cylinders, transmission_type },
  } = formik

  useEffect(() => {
    const opions = []
    if (data?.selections?.trims) {
      data?.selections?.trims.length > 0
        ? data?.selections?.trims.map((trim) => {
            opions.push({ value: trim.name, label: trim.name })
          })
        : opions.push({
            value: data?.attributes?.trim,
            label: data?.attributes?.trim,
          })
    } else {
      opions.push({
        value: data?.attributes?.trim,
        label: data?.attributes?.trim,
      })
    }
    setVehicleTrimeList(opions)
  }, [data])

  const vehicleSaveAsDrafthandler = async () => {
    const errors = await formik.validateForm()
    formik.setTouched({
      vin_number: true,
      make: true,
      model: true,
      year: true,
      trim: true,
      odometer: true,
      fuel_type: true,
      engine: true,
      engine_cylinders: true,
      transmission_type: true,
      drivetrain: true,
      standard_seating: true,
      doors: true,
      username: true,
    })

    if (Object.keys(errors).length === 0) {
      handleSubmit(formik.values, formik, 'drafthandler')
    }
  }

  return (
    <CustomLoadingSection isLoading={isLoading}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-10'>
            <Field
              name='username'
              label='Select Seller'
              placeholder='Select Seller'
              component={CustomSelect}
              options={sellersList}
              value={sellersList?.find(
                (option) => option.value === formik.values.username,
              )}
              required
            />
            {vehicleTrimeList.length > 0 && (
              <Field
                name='trim'
                label='Trim'
                placeholder='Enter Trim'
                component={CustomSelect}
                options={vehicleTrimeList}
                value={
                  vehicleTrimeList.find((option) => option.value === trim) ?? []
                }
                required
              />
            )}
            <Field
              name='fuel_type'
              label='Fuel Type'
              placeholder='Select Fuel Type'
              component={CustomSelect}
              options={fuelTypeList}
              value={
                fuelTypeList.find((option) => option.value === fuel_type) ?? []
              }
              required
            />
            <Field
              name='engine'
              label='Engine'
              placeholder='Transmission'
              component={CustomInput}
              className='form-field'
              isDisabled={engine}
              required
            />
            <Field
              name='engine_cylinders'
              label='Engine Cylinders'
              placeholder='Select Engine Cylinders'
              component={CustomSelect}
              options={engineCylindersList}
              value={
                engineCylindersList.find(
                  (option) => option.value === engine_cylinders,
                ) ?? []
              }
              isDisabled={engine_cylinders}
              required
            />
            <Field
              name='transmission_type'
              label='Transmission Type'
              component={CustomSelect}
              options={transmissionTypeList}
              value={
                transmissionTypeList.find(
                  (option) => option.value === transmission_type,
                ) ?? []
              }
              required
              isDisabled={transmission_type}
            />
            <Field
              name='drivetrain'
              label='Drivetrain'
              component={CustomRadioInput}
              options={drivetrainList}
              className='custom-radio'
              required
            />
            <Field
              name='standard_seating'
              label='Seating'
              component={CustomRadioInput}
              options={passengerList}
              required
              className='custom-radio'
            />
            <Field
              name='doors'
              label='Doors'
              component={CustomRadioInput}
              options={doorsList}
              required
              className='custom-radio'
            />
          </div>
          <div className='mt-10 flex flex-col  xs:flex-row gap-2 justify-end'>
            <button
              className='next-prev-button btn btn-primary-ico'
              type='button'
              disabled={isSubmitting}
              onClick={vehicleSaveAsDrafthandler}
            >
              {isLoading ? (
                <div className='w-5 h-5 mx-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent' />
              ) : (
                <Fragment>
                  Save as Draft <TiArrowDown />
                </Fragment>
              )}
            </button>

            <button
              className='next-prev-button  btn btn-primary-ico'
              type='submit'
              disabled={isSubmitting}
            >
              {isLoading ? (
                <div className='h-5 w-5 mx-auto animate-spin rounded-full border-2 border-solid border-white border-t-transparent' />
              ) : (
                <Fragment>
                  Save & Next
                  <MdArrowRightAlt />
                </Fragment>
              )}
            </button>
          </div>
        </form>
      </FormikProvider>
    </CustomLoadingSection>
  )
}

export default VinDetails
